// COINS
import COIN from '../images/coins/COIN.png';
import CRED from '../images/coins/CRED.png';
import TRSH from '../images/coins/TRSH.png';
import UNI from '../images/coins/UNI.png';
import WHALE from '../images/coins/WHALE.png';
import LINK from '../images/coins/LINK.png';
import RNG from '../images/coins/RNG.png';

// NFTs
import COIN1NFT from '../images/nfts/coin-1.png';
import CRED1NFT from '../images/nfts/cred-1.gif';
import TRSH1NFT from '../images/nfts/trsh-1.png';

import COIN2NFT from '../images/nfts/coin-2.gif';
import CRED2NFT from '../images/nfts/cred-2.gif';
import UNI2NFT from '../images/nfts/uni-2.gif';

import COIN3NFT from '../images/nfts/coin-3.gif';
import CRED3NFT from '../images/nfts/cred-3.gif';
import WHALE3NFT from '../images/nfts/whale-3.gif';

import COIN4NFT from '../images/nfts/coin-4.jpg';
import CRED4NFT from '../images/nfts/cred-4.gif';
import LINK4NFT from '../images/nfts/link-4.gif';

import COIN5NFT from '../images/nfts/coin-5.gif';
import CRED5NFT from '../images/nfts/cred-5.gif';
import RNG5NFT from '../images/nfts/rng-5.gif';

import COIN6NFT from '../images/nfts/coin-6.png';
import CRED6NFT from '../images/nfts/cred-6.gif';
import RNG6NFT from '../images/nfts/rng-6.gif';

import COIN7NFT from '../images/nfts/coin-7.gif';
import CRED7NFT from '../images/nfts/cred-7.jpg';
import RNG7NFT from '../images/nfts/rng-7.gif';

export default {
	"coin-1" : {
		"title" : "COIN WEEK 1",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN1NFT,
		"totalSupply" : 1000000,
		"shardToken" : "SKULL",
		"niftex" : "https://www.niftex.com/launches/details/SKULL",
		"opensea" : "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/14914",
		"farmContract" : {
			"mainnet" : "0xcdB2a49667c1b3Eeb083159ea3bDb6dc1D5aBecf",
			"rinkeby" : "0x03e156e08F8596ea0ba0A9B72F2E81c0Ad7D946F"
		},
		"shardContract" : {
			"mainnet" : "0xcCE50fD7f41574AdB8fA600055B74303111f2e40",
			"rinkeby" : "0x74CEa454b9CeE39D18C1A319bf2749388fe33945"
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : "0x34bFe1342768D6553C970c826392B20EbC0411E2"
		},
		"shardGovernor" : "0xf0e17fd396f4653490db5fc702560cf44fe972f6"
	},
	"cred-1" : {
		"title" : "CRED WEEK 1",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED1NFT,
		"totalSupply" : 1000000,
		"shardToken" : "WNDR",
		"niftex" : "https://www.niftex.com/launches/details/WNDR",
		"opensea" : "https://opensea.io/assets/0x22adcaf1d6b5213a76bfddab6d4d38c25ce7c029/73",
		"farmContract" : {
			"mainnet" : "0x606517B453Fa0bfE8a39bda2d3401fF543506355",
			"rinkeby" : "0xDbeD1819c165Af8395d0a0b9a779EB70316a464B"
		},
		"shardContract" : {
			"mainnet" : "0x783E2dcFbf6F40c6943d9AdeD6113C7013c46c9b",
			"rinkeby" : "0xbe2e11f1fA79AbB81d1f37D4ae8916ee50A00676"
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : "0x3c8fbce8ed206e80aa5dfc45c8a2bcfc54235e9f"
		},
		"shardGovernor" : "0x2f7627dfc5b473b9003e2dd69a0855a9dffc8b86"
	},
	"trsh-1" : {
		"title" : "TRSH WEEK 1",
		"token" : "TRSH",
		"isRoll" : false,
		"image" : TRSH,
		"nftImage" : TRSH1NFT,
		"totalSupply" : 1000000,
		"shardToken" : "FRAME",
		"niftex" : "https://www.niftex.com/launches/details/FRAME",
		"opensea" : "https://opensea.io/assets/0x1702bea2755d076adf737b785926b086eff36f75/212",
		"farmContract" : {
			"mainnet" : "0xD4639AE0A59287a72d27D22bD374d848cbb798DB",
			"rinkeby" : "0x8AdfEcf3A3ef16e0Fb397466290146fCD289d999"
		},
		"shardContract" : {
			"mainnet" : "0xA69fCAaA3F5AE04E63a7FeC7Aff9277BC0BDf8B4",
			"rinkeby" : "0xF8ba140e1683C0B7C37b9583f8765A72476121E3"
		},
		"tokenContract" : {
			"mainnet" : "0x0f46eba0bafe2739d9f3c43a48bb84bee1f6644f",
			"rinkeby" : "0x0a0Cc964f3414672c82a13d726F0228AC4331dBe"
		},
		"shardGovernor" : "0x0357C4c31dD2eF87fbFb7599C8403D30313C587c"
	},
	"coin-2" : {
		"title" : "COIN WEEK 2",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN2NFT,
		"totalSupply" : 1000000,
		"shardToken" : "MWNN2",
		"niftex" : "https://www.niftex.com/launches/details/MWNN2",
		"opensea" : "https://opensea.io/assets/0xcbe642d1b28caeff5f8377560db29e43ba7ac200/1",
		"farmContract" : {
			"mainnet" : "0xe97A7C6056CB5E1E13D22F332F8d6cA6DB5AE7dC",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0xe85a37e3fbcf9ec782f06477604de8c211b5c218",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x889efb44b6793abf8fb0036863bdb829dab6d416"
	},
	"cred-2" : {
		"title" : "CRED WEEK 2",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED2NFT,
		"totalSupply" : 1000000,
		"shardToken" : "WNDR2",
		"niftex" : "https://www.niftex.com/launches/details/WNDR2",
		"opensea" : "https://opensea.io/assets/0x22adcaf1d6b5213a76bfddab6d4d38c25ce7c029/74",
		"farmContract" : {
			"mainnet" : "0xc44373e6A2D7117115C655528C1406Df48d0cfeC",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x71d0d05c9772803ccfd4f39ae0e61b35f2792c2f",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xb261fb8a2b6e4bc5f9b630a138000856a3e66e71"
	},
	"uni-2" : {
		"title" : "UNI WEEK 2",
		"token" : "UNI",
		"isRoll" : false,
		"image" : UNI,
		"nftImage" : UNI2NFT,
		"totalSupply" : 1000000,
		"shardToken" : "CBOP",
		"niftex" : "https://www.niftex.com/launches/details/CBOP",
		"opensea" : "https://opensea.io/assets/0xa399e2bc8337608602cad9724caf4c136b1b68d8/6",
		"farmContract" : {
			"mainnet" : "0x8725Bd6BB6BC5b6b35315922F3E07eF9e00ea100",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0xbbcdee12f16696f887a009b059561561d4479b45",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x82fb19012fe38296f5c9da70164ed27ffd231a06"
	},
	"coin-3" : {
		"title" : "COIN WEEK 3 #1",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN3NFT,
		"totalSupply" : 1000000,
		"shardToken" : "NPMIA",
		"niftex" : "https://www.niftex.com/launches/details/NPMIA",
		"opensea" : "https://opensea.io/assets/0x22adcaf1d6b5213a76bfddab6d4d38c25ce7c029/75",
		"farmContract" : {
			"mainnet" : "0x88b8929061f480c9b9ad0a9697e43e307c097b49",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x22972f3900d6fd68dc8c35249936b16f21b0b836",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x784b4ff99dc92b2527aa14da496d013199b7d78e"
	},
	"cred-3" : {
		"title" : "CRED WEEK 3 #1",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED3NFT,
		"totalSupply" : 1000000,
		"shardToken" : "HYPER",
		"niftex" : "https://www.niftex.com/launches/details/HYPER",
		"opensea" : "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/15600",
		"farmContract" : {
			"mainnet" : "0xa6012ab844ceb8caddd38e8761e71a05b2bcda30",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x4982e5c944a6aa5a6aa12e48bb5a4c0b70424080",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x1c8393d35e8ea4228df398d3667cf914b60ee5c7"
	},
	"whale-3" : {
		"title" : "WHALE WEEK 3 #1",
		"token" : "WHALE",
		"isRoll" : true,
		"image" : WHALE,
		"nftImage" : WHALE3NFT,
		"totalSupply" : 1000000,
		"shardToken" : "IFREQ",
		"niftex" : "https://www.niftex.com/launches/details/IFREQ",
		"opensea" : "https://opensea.io/assets/0x09d2a34aa67b407a925b1b67536bfad80b375306/1",
		"farmContract" : {
			"mainnet" : "0xf4eb45ce4ead9af5fdc1ac3492711d344a479130",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x0b078bac82e277ed7b9cefb91935ef72dca6e0de",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x9355372396e3f6daf13359b7b607a3374cc638e0",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x3649de7d1085bc99c163d40f03d3ce7ae8b8dfcf"
	},
	"coin-4" : {
		"title" : "COIN WEEK 3 #2",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN4NFT,
		"totalSupply" : 1000000,
		"shardToken" : "MANIA",
		"niftex" : "https://www.niftex.com/launches/details/MANIA",
		"opensea" : "https://opensea.io/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/226176",
		"farmContract" : {
			"mainnet" : "0x96137fb519be84cd874b9742fbcca3c59dd38e3c",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x1aff4291843a69ce54fb25c227641e2f01f43ff3",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x12bce3c3cf61f83f4b8a9d13551f002b08e47459"
	},
	"cred-4" : {
		"title" : "CRED WEEK 3 #2",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED4NFT,
		"totalSupply" : 1000000,
		"shardToken" : "WAKE",
		"niftex" : "https://www.niftex.com/launches/details/WAKE",
		"opensea" : "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/16009",
		"farmContract" : {
			"mainnet" : "0x817a258bf950c3cde78ce27d98d7f77362bfa96a",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x33b07738f34d7b46e4d4e74442a70020762cf8ae",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xeb6269a6298502cb10400e77a53c16c212354896"
	},
	"link-4" : {
		"title" : "LINK WEEK 3 #2",
		"token" : "LINK",
		"isRoll" : false,
		"image" : LINK,
		"nftImage" : LINK4NFT,
		"totalSupply" : 1000000,
		"shardToken" : "SKLSP",
		"niftex" : "https://www.niftex.com/launches/details/SKLSP",
		"opensea" : "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13969",
		"farmContract" : {
			"mainnet" : "0x1618342de55cfdec04a0a55ba2fa649b513846b0",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0xe018ce12dfcec3a8dcc94f4c1946e1dd7c6fe310",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x514910771af9ca656af840dff83e8264ecf986ca",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x1aa7873ddf311a7d7929c3554461dd5a8099146a"
	},
	"coin-5" : {
		"title" : "COIN WEEK 4",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN5NFT,
		"totalSupply" : 1000000,
		"shardToken" : "NFCHG",
		"niftex" : "https://www.niftex.com/launches/details/NFCHG",
		"opensea" : "https://opensea.io/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/239726",
		"farmContract" : {
			"mainnet" : "0x779072E24a54CDEe35260B485219Bd7f623d10E8",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x5c29cee07983a390c37f3f068fa00374a998ea2d",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xE484cb303d524f4Be5911432b0D543624632037a"
	},
	"cred-5" : {
		"title" : "CRED WEEK 4",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED5NFT,
		"totalSupply" : 1000000,
		"shardToken" : "GUARD",
		"niftex" : "https://www.niftex.com/launches/details/GUARD",
		"opensea" : "https://opensea.io/assets/0x39085940f0b745c4796243f35633c02cb6953d40/1",
		"farmContract" : {
			"mainnet" : "0x37Da073fa5981Ce4cC2e5Af9E2000DDee374891F",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x3dfe49aad52c8f512981bbf8a077ebdc5115a131",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xaC9c5d11de51771849e86E3F1C1f67F40D6B3c84"
	},
	"rng-5" : {
		"title" : "RNG WEEK 4",
		"token" : "RNG",
		"isRoll" : true,
		"image" : RNG,
		"nftImage" : RNG5NFT,
		"totalSupply" : 1000000,
		"shardToken" : "RNGSH",
		"niftex" : "https://www.niftex.com/launches/details/RNGSH",
		"opensea" : "https://opensea.io/assets/0xd97d2e469be98e48c1c252843fe2203cd1b2de22/198",
		"farmContract" : {
			"mainnet" : "0x7AEbaaA4cc4a8f3b17225A392A64559Ae6e8Bb97",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x0edf0a28311fd9478c13dc2a80c823ad3eb8683b",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xa2f96EF6ed3d67A0352e659B1E980f13e098619F",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xBDA6dc78fA8bc2786Bc8bff66bE56b934206ee8D"
	},
	"coin-6" : {
		"title" : "COIN WEEK 6",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN6NFT,
		"totalSupply" : 1000000,
		"shardToken" : "CRCIA",
		"niftex" : "https://www.niftex.com/launches/details/CRCIA",
		"opensea" : "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13074",
		"farmContract" : {
			"mainnet" : "0x684D1b10265e0F0ff71fE15640ff1c11d326AEd3",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x51720681A0CBCdE8Ab602F0Eb9643986EB2B70ff",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x45ddC6f59b2FbFCacB5B13E504de22FC7B4eC1C9"
	},
	"cred-6" : {
		"title" : "CRED WEEK 6",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED6NFT,
		"totalSupply" : 1000000,
		"shardToken" : "TRIPW",
		"niftex" : "https://www.niftex.com/launches/details/TRIPW",
		"opensea" : "https://opensea.io/assets/0x42ea135d8e9e90657d6d5f715f59794c68be7f17/154",
		"farmContract" : {
			"mainnet" : "0xd07164bFC525c17D90B99a71779B58B21C7503de",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x971e22a354FE026fAF437Dd283a21fD5bf57B4FA",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x6641fB7a2CB90888c3f7f586ea300D41Ac6aBDAC"
	},
	"rng-6" : {
		"title" : "RNG WEEK 6",
		"token" : "RNG",
		"isRoll" : true,
		"image" : RNG,
		"nftImage" : RNG6NFT,
		"totalSupply" : 1000000,
		"shardToken" : "THINK",
		"niftex" : "https://www.niftex.com/launches/details/THINK",
		"opensea" : "https://opensea.io/assets/0x09d2a34aa67b407a925b1b67536bfad80b375306/2",
		"farmContract" : {
			"mainnet" : "0xA3e882776947Aaa7a53d716c2Cfe79aEC5bb5Dc0",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0xBbB60Df786dE6f9f5A79F1fEc7d371045d7DEc0c",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xa2f96EF6ed3d67A0352e659B1E980f13e098619F",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x25dec0e786e9d295eedfa26f9fe6a0ef86148ead"
	},
	"coin-7" : {
		"title" : "COIN WEEK 7",
		"token" : "COIN",
		"isRoll" : false,
		"image" : COIN,
		"nftImage" : COIN7NFT,
		"totalSupply" : 1000000,
		"shardToken" : "CYBVB",
		"niftex" : "https://www.niftex.com/launches/details/CYBVB",
		"opensea" : "https://opensea.io/assets/0xcbe642d1b28caeff5f8377560db29e43ba7ac200/6",
		"farmContract" : {
			"mainnet" : "0x86583c9f2c4e2998e4c1c51c97186ad5187aedc0",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0xb8588f8fe1c2a8656bee8ffdee1c1469a0556be6",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0x87b008E57F640D94Ee44Fd893F0323AF933F9195",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x0C230A2648645Cc2620Db093C160b862e296885F"
	},
	"cred-7" : {
		"title" : "CRED WEEK 7",
		"token" : "CRED",
		"isRoll" : false,
		"image" : CRED,
		"nftImage" : CRED7NFT,
		"totalSupply" : 1000000,
		"shardToken" : "NOONE",
		"niftex" : "https://www.niftex.com/launches/details/NOONE",
		"opensea" : "https://opensea.io/assets/0x60f80121c31a0d46b5279700f9df786054aa5ee5/59521",
		"farmContract" : {
			"mainnet" : "0xf873D055414327c6506eF9199402b538D277121f",
			"rinkeby" : ""
		},
		"shardContract" : {
			"mainnet" : "0x0a7ef13bf9ebaabbb7912b7f859b164a725e8de7",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xED7Fa212E100DFb3b13B834233E4B680332a3420",
			"rinkeby" : ""
		},
		"shardGovernor" : "0x3db3a73dd44f150981825f07b46ff345c1e096aa"
	},
	"rng-7" : {
		"title" : "RNG WEEK 7",
		"token" : "RNG",
		"isRoll" : true,
		"image" : RNG,
		"nftImage" : RNG7NFT,
		"totalSupply" : 1000000,
		"shardToken" : "CONST",
		"niftex" : "https://www.niftex.com/launches/details/CONST",
		"opensea" : "https://opensea.io/assets/0xb89bd7ed0551746a8e49c760f9ba92a052d25e2d/5",
		"farmContract" : {
			"mainnet" : "0x9e38c95DC661534f3fF2C372Ba3620F6dF5EdBB5",
			"rinkeby" : ""
		},
		"shardContract" : {	
			"mainnet" : "0x3bbffaa97cae3514eceebbcd9b5d3840ecf71301",
			"rinkeby" : ""
		},
		"tokenContract" : {
			"mainnet" : "0xa2f96EF6ed3d67A0352e659B1E980f13e098619F",
			"rinkeby" : ""
		},
		"shardGovernor" : "0xb07f7a3216f8d7a986b5491723e45c4db6d267f0"
	}
};
